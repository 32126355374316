<template>
  <ReferenceListComponent/>
</template>

<script>
import ReferenceListComponent from '@/components/ReferenceListComponent.vue';

export default {
  name: 'ReferencesView',
  components: {
    ReferenceListComponent,
  },
  props: [],
  mounted() {

  },
};
</script>
