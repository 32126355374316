<template>
  <CustomModal v-model="showReferenceModal" @confirm="confirm" @cancel="cancel">
    <template v-slot:title>{{reference != null && reference._id != null ? $t('modal.titleEditReference') : $t('modal.titleCreateReference')}}</template>
    <div class="form" v-if="reference != null && contacts != null">
      <div class="row" >
        <div class="label">{{ $t('modal.labelName') }}:</div>
        <div class="value"><input v-model="reference.name" type="text"/>
          <br/><div v-if="errorName != null" class="fieldError">{{errorName}}</div>
        </div>
      </div>

      <div class="row">
        <div class="label">{{ $t('modal.labelContact') }}:</div>
        <div class="value">
          <select @change="setContact" v-model="reference.contact">
            <option value="-1" >{{ $t('general.pleaseChoose') }}</option>
            <option :value="c._id" v-for="c in contacts" :key="c._id">{{c.firstname}} {{c.lastname}}<span v-if="c.email != null && c.email !== ''"> ({{c.email}})</span></option>
          </select>
        </div>
      </div>
    </div>
  </CustomModal>
</template>

<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle,no-bitwise */

import CustomModal from '@/components/modals/CustomModal.vue';
import ServiceHelper from '@/helpers/ServiceHelper';
import CryptoHelper from '@/helpers/CryptoHelper';
import { sha256 } from 'js-sha256';

export default {
  name: 'CreateOrEditReferenceModal',
  props: ['referenceToEdit'],
  components: {
    CustomModal,
  },
  data() {
    return {
      showReferenceModal: false,
      reference: null,
      contactQuery: '',
      contacts: [],
      errorFirstname: null,
      errorLastname: null,
      errorEmail: null,
      errorEmptyOrInsufficientPassword: null,
      errorPasswordsDontMatch: null,
      errorInvalidEmail: null,
      showPassword: false,
      emailChecked: true,
      emailOnEnter: null,
      permissions: {},
      /* eslint-disable no-useless-escape */
      strongRegex: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\(\)\-\._!@#\$%\^&\*\|])(?=.{8,})'),
      /* eslint-disable no-useless-escape */
      mediumRegex: new RegExp('^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})'),
    };
  },
  watch: {
    referenceToEdit(newVal) {
      this.reference = newVal;
      this.emailOnEnter = this.reference != null && this.reference.email != null ? this.reference.email.toLowerCase() : null;
    },
  },
  mounted() {
    this.reference = this.referenceToEdit;
    this.fetchContacts();
    this.updatePermissions();
  },
  methods: {
    getContactName(g) {
      if (this.contactsById != null && this.contactsById[g] != null) {
        return this.contactsById[g].name;
      }
      return 'unknown';
    },
    async show() {
      this.contactQuery = '';
      this.contacts = [];
      this.errorFirstname = null;
      this.errorLastname = null;
      this.errorEmail = null;
      this.errorEmptyOrInsufficientPassword = null;
      this.errorPasswordsDontMatch = null;
      this.errorInvalidEmail = null;
      await this.fetchContacts();
      this.showReferenceModal = true;
      this.updatePermissions();
    },
    updatePermissions() {
      this.permissions.web = this.reference != null && this.reference.permissions != null && (this.reference.permissions & 1) > 0;
      this.permissions.colada = this.reference != null && this.reference.permissions != null && (this.reference.permissions & 4) > 0;
    },
    hide() {
      this.showReferenceModal = false;
    },
    async confirm() {
      this.errorName = null;

      let hasErrors = false;
      if (this.reference.name == null || this.reference.name.trim() === '') {
        this.errorName = this.$t('validationError.nameEmpty');
        hasErrors = true;
      }

      if (this.reference.contact === -1) {
        delete this.reference.contact;
      }

      if (hasErrors) {
        return;
      }

      // encrypt and send reference
      try {
        this.reference.name = CryptoHelper.encrypt(this.reference.name);

        if (this.reference._id != null) {
          // update
          await ServiceHelper.sendDataToBackend(`reference/${this.reference._id}`, this.reference, 'PUT');
        } else {
          // create
          await ServiceHelper.sendDataToBackend('reference', this.reference);
        }
        this.$emit('refresh');
      } catch (error) {
        console.error(error);
      }
      this.hide();
    },
    cancel() {
      this.hide();
    },
    async fetchContacts() {
      console.log('getting contacts');
      const data = await ServiceHelper.loadDataFromBackend('contacts');
      this.contacts = [];
      data.payload.forEach((entry) => {
        console.log('entry', JSON.stringify(entry));
        const e = entry;
        if (entry.firstname != null) {
          e.firstname = CryptoHelper.decrypt(e.firstname).toString('utf-8');
        }
        if (entry.lastname != null) {
          e.lastname = CryptoHelper.decrypt(e.lastname).toString('utf-8');
        }
        if (entry.email != null) {
          e.email = CryptoHelper.decrypt(e.email).toString('utf-8');
        }
        this.contacts.push(e);
        console.log('contacts', this.contacts);
      });
      return null;
    },
    async checkEmail() {
      this.emailChecked = false;
      this.errorEmail = null;
      if (this.emailOnEnter != null && this.emailOnEnter === this.reference.email.toLowerCase()) {
        this.emailChecked = true;
        return;
      }
      try {
        const data = await ServiceHelper.loadDataFromBackend(`reference/checkEmail/${sha256(this.reference.email.toLowerCase())}`);
        this.emailChecked = data.payload.emailIsAvailable;
        this.errorEmail = this.emailChecked ? null : this.$t('validationError.emailNotAvailable');
      } catch (e) {
        console.error(e);
        this.emailChecked = false;
      }
    },
  },
};
</script>

<style scoped>

::v-deep(.modal_content .form .row .tagsInput) {
  width: 100%;
  display: inline-block;
  background-color: white;
  border-bottom: 1px solid lightgray;
  position: relative;
}

::v-deep(.modal_content .form .row .tagsInput .tagSelection) {
  position: absolute;
  max-width: 100%;
  width: 100%;
  top: 100%;
  max-height: 150px;
  overflow-y: auto;
  z-index: 9999;
  border: 1px solid gray;
  padding: 2px;
  box-shadow: 4px 4px 8px gray;
  background-color: rgba(255,255,255,0.8);
}

::v-deep(.modal_content .form .row .tagsInput .tagSelection .tagOption) {
  cursor: pointer;
  padding: 4px;
  transition: all ease-in 100ms;
}

::v-deep(.modal_content .form .row .tagsInput .tagSelection .tagOption:hover) {
  background-color: lightsalmon;
}

::v-deep(.modal_content .form .row .tagsInput input) {
  width: 80px;
  border: 0;
}

::v-deep(.modal_content .form .row .tagsInput .tag) {
  font-size: 12px;
  border-radius: 12px;
  line-height: 16px;
  background-color: #2c5e4a;
  padding: 4px 24px 4px 8px;
  color: white;
  margin-bottom: 4px;
  margin-right: 4px;
  white-space: nowrap;
  position: relative;
  display: inline-block;
}

::v-deep(.modal_content .form .row .tagsInput .tag .delete) {
  position: absolute;
  line-height: 16px;
  right: 8px;
  top: 3px;
  font-size: 18px;
  cursor: pointer;
}
::v-deep(.modal_content .form .row .tagsInput .tag .delete:hover) {
  font-weight: 800;
}

</style>
