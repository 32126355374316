<template>
  <div class="referenceList">
    <div class="page-title">{{ $t('pageTitle.references') }}</div>
    <div class="actionBar">
      <div class="actionButton bgBrown refresh" @click="loadReferences"><i class="fas fa-sync"></i>
        <div class='actionButtonTitle'>{{ $t('general.refresh') }}</div>
      </div>
    </div>
    <div class="topNav">
      <router-link to="/settings"><div class='navButton'><i class="fas fa-arrow-left"/> {{ $t('general.back') }}</div></router-link>
    </div>
    <div class="screen-container" ref="screenContainer" :style='{ height: screenContainerHeight }'>
      <div class="listWrapper">
        <EmptyListAnimationComponent v-if="references != null && references.length == 0"/>
        <div class="listItemWrapper" v-for="entry in references" :key="entry._id">
          <div class="listItem reference" @click="editReference(entry)"><div class="icon"><i class="far fa-circle"/></div>
            {{entry.name}}
          </div>
          <div class="listActionWrapper">
            <span class="listAction referenceAction" @click="editReference(entry)"><i class="fas fa-pen"/></span>
            <span class="listAction referenceAction" @click="deleteReference(entry._id)"><i class="fas fa-trash"/></span>
          </div>
        </div>
      </div>
    </div>
    <div class="floating-add-button" @click="addReference">
      <img class="floating-add-button-icon" src="../assets/icons/plus_white.png" />
    </div>
    <CreateOrEditReferenceModal :referenceToEdit="referenceToEdit" ref="createOrEditReferenceModal" @refresh="loadReferences"/>
    <ConfirmModal ref="confirmDeleteModal" :title="$t('modal.titleDelete')" :text="$t('modal.textDelete')"/>
  </div>
</template>

<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle */

import ServiceHelper from '@/helpers/ServiceHelper';
import CryptoHelper from '@/helpers/CryptoHelper';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import ScreenHeightMixin from '@/mixins/ScreenHeightMixin.vue';
import EmptyListAnimationComponent from '@/components/EmptyListAnimationComponent.vue';

import CreateOrEditReferenceModal from '@/components/modals/CreateOrEditReferenceModal.vue';

export default {
  name: 'ReferenceListComponent',
  props: [],
  mixins: [ScreenHeightMixin],
  components: {
    CreateOrEditReferenceModal,
    ConfirmModal,
    EmptyListAnimationComponent,
  },
  data() {
    return {
      references: [],
      referenceToEdit: null,
    };
  },
  watch: {
  },
  async mounted() {
    this.loadReferences();
  },
  methods: {
    async loadReferences() {
      this.references = [];
      try {
        const data = await ServiceHelper.loadDataFromBackend('references');
        data.payload.forEach((entry) => {
          const e = entry;
          if (e.name != null) e.name = CryptoHelper.decrypt(e.name).toString('utf-8');
        });
        this.references = data.payload;
      } catch (error) {
        console.error(error);
      }
      return null;
    },
    async addReference() {
      this.referenceToEdit = { contact: -1 };
      this.$refs.createOrEditReferenceModal.show();
      return null;
    },
    async deleteReference(referenceId) {
      if (await this.$refs.confirmDeleteModal.show()) {
        try {
          const url = `reference/${referenceId}`;
          await ServiceHelper.deleteDataFromBackend(url);
          this.loadReferences();
        } catch (error) {
          console.error(error);
          if (error.errorCode && parseInt(error.errorCode, 10) > -1) {
            alert(this.$t(`error.code_${error.errorCode}`));
          }
        }
      }
      return null;
    },
    async editReference(reference) {
      this.referenceToEdit = JSON.parse(JSON.stringify(reference));
      this.$refs.createOrEditReferenceModal.show();
      return null;
    },
  },
  computed: {
  },
};
</script>

<style scoped>
@import url('../styles/list.module.css');

.listWrapper .listItemWrapper:nth-child(odd) {
  background-color: var(--color_list_1);
}
.listWrapper .listItemWrapper:nth-child(even) {
  background-color: var(--color_list_2);
}

.is-admin-marker {
  color: #a1a1a1;
}
</style>
